<template>
  <div class="unauthorized">
    <template v-if="isLoggedIn">
      {{ $t('errors.no_modules') }}
      <v-btn
        color="primary"
        @click="logout({ forceBackToLogin: true })"
      >
        {{ $t('ui.logout') }}
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { VBtn } from 'vuetify/lib';
export default {
  name: 'Unauthorized',
  components: {
    VBtn,
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
  }
};
</script>

<style lang="scss" scoped>
  .unauthorized {
    text-align: center;
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .v-btn {
      margin-top: 2rem;
    }
  }
</style>
